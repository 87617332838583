<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
export default{
  components:{
    IonApp,
    IonRouterOutlet
  },
  mounted(){
    window.screen.orientation.lock("landscape")
  }
}
</script>
